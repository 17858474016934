import React, { useState, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { useIntl } from 'react-intl';
import queryString from 'query-string';

import { useDispatch } from 'react-redux';
import NotificationsActions from '~redux/notifications';
import PromptService from '~services/prompt';
import { submitUnsubscribe } from './utils';
import { useLayout } from '~hooks/useLayout';
import { useSeo } from '~hooks/useSeo';
import { AuthContext } from '~contexts/auth';

import Hero from '~components/hero';

import Radio from '~components/forms/radio';
import Button from '~components/button';

import './style.scss';

const UnsubscribeActions = () => {
  const intl = useIntl();
  const location = useLocation();
  const dispatch = useDispatch();
  const { isAuthenticated, signOut } = useContext(AuthContext);

  useLayout({});
  useSeo(
    intl.formatMessage({
      id: 'containers.unsubscribe.seo.title',
    })
  );

  const code = queryString.parse(location.search).code;
  const newUsersAlerts = queryString.parse(location.search).newUsersAlerts === 'true' ? true : false;
  const personalMessages = queryString.parse(location.search).personalMessages === 'true' ? true : false;

  const [accountAction, setAccountAction] = useState('pause-advertisement');
  const [isSaving, setIsSaving] = useState(false);
  const [isSaved, setIsSaved] = useState(false);

  const handleSave = async () => {
    const actions = {
      published: false,
      is_deleted: accountAction === 'close-account',
      user_alert: accountAction === 'close-account' ? false : newUsersAlerts,
      personal_message:  accountAction === 'close-account' ? false : personalMessages,
    };

    try {
      setIsSaving(true);
      await submitUnsubscribe({ actions, code });
      dispatch(
        NotificationsActions.addNotification(
          intl.formatMessage({
            id: `containers.unsubscribe.notification.success`,
            defaultMessage: 'Unsubscribed successfully',
          })
        )
      );
      setIsSaved(true);
      if(isAuthenticated && personalMessages && accountAction === 'close-account') {
        signOut();
      }
    } catch (err) {
      console.log('Error while archiving account');
      PromptService.error(err);
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <>
      <section className="c-page l-container">
        <Hero.Wrapper>
          {isSaved ? (
            <Hero.Intro>
              {intl.formatMessage({
                id: 'containers.unsubscribe.success-message',
              })}
            </Hero.Intro>
          ) : (
            <Hero.Intro>
              {intl.formatMessage({
                id: 'containers.unsubscribe.account-actions.introduction',
              })}
            </Hero.Intro>
          )}
        </Hero.Wrapper>

        <div className="c-unsubscribe l-page-content">
          {!code ? (
            <div>Invalid code</div>
          ) : !isSaved ? (
                <div>
                  <Hero.Wrapper>
                  </Hero.Wrapper>
                  <div >
                    <Radio.Container value={accountAction} onChange={setAccountAction} className="c-radio__container--horizontal c-unsubscribe-checkboxes">
                      <Radio.Option value="close-account" className="c-radio--bordered c-radio--with-children">
                        <div className="c-radio__children">
                          <div className="c-radio__title">
                            {intl.formatMessage({
                              id: 'containers.unsubscribe.account-actions.close-account.title',
                            })}
                          </div>
                          <div className="c-radio__content">
                            {intl.formatMessage({
                              id: 'containers.unsubscribe.account-actions.close-account.content',
                            })}
                          </div>
                        </div>
                      </Radio.Option>
                      <Radio.Option
                        value="pause-advertisement"
                        className="c-radio--bordered c-radio--with-children"
                      >
                        <div className="c-radio__children">
                          <div className="c-radio__title">
                            {intl.formatMessage({
                              id: 'containers.unsubscribe.account-actions.pause-advertisement.title',
                            })}
                          </div>
                          <div className="c-radio__content">
                            {intl.formatMessage({
                              id: 'containers.unsubscribe.account-actions.pause-advertisement.content',
                            })}
                          </div>
                        </div>
                      </Radio.Option>
                    </Radio.Container>
                  </div>
                </div>
          ) : null}

          {!isSaved && (
            <div className="l-center l-mt2">
              <Button onClick={handleSave} isLoading={isSaving}>
                {intl.formatMessage({
                  id: 'containers.unsubscribe.save',
                })}
              </Button>
            </div>
          )}
        </div>
      </section>
    </>
  );
};

export default UnsubscribeActions;
